import { Injectable } from '@angular/core';

import { THubMethods } from '@core/models/hub-event.model';
import { BaseHub } from '@core/services/hubs/base.hub';

@Injectable({
  providedIn: 'root',
})
export class TechPortalMainHub {
  constructor(public readonly baseHub: BaseHub) {
    baseHub.setConfig('claims', [
      THubMethods.TechPhotosUploaded,
      THubMethods.TechPhotosDeleted,
    ]);
    this.subscribeToUpdates();
  }

  startSignalR() {
    this.baseHub.startSignalR();
  }

  subscribeToUpdates() {}
}
