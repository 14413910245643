import { importProvidersFrom } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { CoreModule } from '@core/core.module';
import { TECH_PORTAL_FORM_FIELD_DEFAULT_OPTIONS } from '@core/providers/material/form-field.provider';
import { MainHub } from '@core/services/hubs/main.hub';

import { TECH_APP_ROUTES } from './app.routes';
import { TechPortalMainHub } from './core/services/tech-portal-main.hub';

export const TECH_APP = {
  providers: [
    importProvidersFrom(
      CoreModule,
      MatDatepickerModule,
    ),
    TECH_PORTAL_FORM_FIELD_DEFAULT_OPTIONS,
    provideRouter(
      TECH_APP_ROUTES,
      // withPreloading(PreloadAllModules),
      // withDebugTracing(),
    ),
    provideAnimations(),
    {
      provide: MainHub,
      useClass: TechPortalMainHub,
    },
  ],
};
