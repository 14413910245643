import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { buildVariables } from '@th-common/assets/config/build-variables';
import { distinctUntilChanged, filter, first, switchMap, withLatestFrom } from 'rxjs/operators';

import { SystemApiService } from '@core/api/system-api.service';
import { AuthService } from '@core/services/auth.service';
import { SystemService } from '@core/services/system.service';
import { ThemingService } from '@core/services/theming.service';
import { NotificationModule } from '@shared/modules/notification/notification.module';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NotificationModule,
    RouterOutlet,
    NgIf,
  ],
})
export class AppComponent implements OnInit {
  loadingError = false;
  showLoader = true;

  constructor(
    private themingService: ThemingService,
    private router: Router,
    private systemApiService: SystemApiService,
    private systemService: SystemService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    const routeSubs = this.router.events
      .pipe(
        filter(e => (
          e instanceof NavigationStart
            || e instanceof NavigationEnd
            || e instanceof NavigationCancel
            || e instanceof NavigationError
        )),
        distinctUntilChanged(),
      )
      .subscribe(e => {
        if (e instanceof NavigationStart || e instanceof NavigationCancel) {
          this.showLoader = true;
        } else if (e instanceof NavigationEnd) {
          this.showLoader = false;
          routeSubs.unsubscribe();
        } else if (e instanceof NavigationError) {
          this.loadingError = true;
        }
      });

    this.themingService.themeSubject$.subscribe(theme => {
      const root = document.documentElement;
      this.themingService.setThemeVariables(theme, root);
    });

    const appRoot = document.querySelector('#app-root');
    if (appRoot) {
      appRoot.setAttribute('build-version', buildVariables.buildNumber);
    }

    this.authService.tokenResolverChecked$
      .pipe(
        withLatestFrom(this.authService.authorized$),
        filter(([tokenResolverChecked, authorized]) => (authorized ? tokenResolverChecked : true)),
        first(),
        switchMap(() => this.systemApiService.buildNumber()),
      )
      .subscribe(buildNumber => {
        this.systemService.buildNumber$.next(buildNumber);
        if (appRoot) {
          appRoot.setAttribute('server-version', buildNumber);
        }
      });
  }
}
